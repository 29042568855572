import { init, setUser } from "@sentry/browser";
import type {
  browserTracingIntegration,
  replayIntegration,
} from "@sentry/browser";

import { getMetaContent } from "../utils";

type Integration =
  | ReturnType<typeof browserTracingIntegration>
  | ReturnType<typeof replayIntegration>;

const initializeSentry = (integrations: Integration[]) => {
  const sentryFrontendKey = getMetaContent("sentry-frontend-key");
  if (sentryFrontendKey) {
    init({
      dsn: sentryFrontendKey,
      environment: getMetaContent("sentry-environment"),
      release: getMetaContent("heroku-revision"),
      integrations,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
    setUser({ email: getMetaContent("user-email") });
  }
};

export default initializeSentry;
